<template>
  <v-card flat tile>
    <v-container>
      <!-- 10 items array for loading, cause the sekeleton loader will not work when there are not items in the v-for -->
      <v-list
        v-for="movie in loading ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] : items"
        :key="movie.id"
        class="pa-0"
      >
        <v-lazy min-height="152" :key="movie.id || movie.item_id">
          <v-skeleton-loader
            tile
            :loading="loading"
            type="list-item-avatar-three-line"
          >
            <router-link
              tag="div"
              class="pa-0 transparent"
              flat
              :to="getTo(movie)"
            >
              <v-container data-v-step="browse_1">
                <v-row no-gutters>
                  <v-col class="shrink pa-0">
                    <v-container fill-height class="pa-0 py-1">
                      <v-row no-gutters>
                        <movie-img
                          v-if="movie.cover || movie.image"
                          :path="movie.cover || movie.image"
                          :blur="
                            movie.protected || movie.item_protected === 'true'
                          "
                        ></movie-img>
                        <v-icon v-else size="80">mdi-filmstrip</v-icon>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col class="grow">
                    <v-list three-line class="pa-0 transparent" width="100%">
                      <v-list-item three-line>
                        <v-list-item-content>
                          <!--<div
                            class="text-overline line-clamp line-clamp-1"
                            style="
                              white-space: initial;
                              -webkit-line-clamp: 1;
                              -webkit-box-orient: vertical;
                              display: -webkit-box;
                            "
                          >
                            {{ getGenresText(movie.genres) }}
                          </div> -->
                          <v-list-item-title
                            class="line-clamp line-clamp-1"
                            style="
                              white-space: initial;
                              -webkit-line-clamp: 1;
                              -webkit-box-orient: vertical;
                              display: -webkit-box;
                            "
                            v-html="`${movie.title || movie.name}`"
                          ></v-list-item-title>
                          <v-progress-linear
                            v-if="
                              ((movie.resume_time / 60) * 100) /
                                movie.duration >
                              0
                            "
                            height="3"
                            :value="
                              ((movie.resume_time / 60) * 100) / movie.duration
                            "
                            :color="
                              ((movie.resume_time / 60) * 100) /
                                movie.duration >
                              0
                                ? 'primary'
                                : 'transparent'
                            "
                          ></v-progress-linear>
                          <v-list-item-subtitle>
                            <span>{{ movie.description }}</span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-container>
            </router-link>
          </v-skeleton-loader>
        </v-lazy>
      </v-list>
    </v-container>
  </v-card>
</template>

<script>
import map from "lodash/map";
import MovieImg from "@/components/vod/MovieImg";

export default {
  name: "MovieCard",
  components: {
    MovieImg,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: "fetching",
    },
  },
  methods: {
    getGenresText(items) {
      return map(items, "title").join(", ");
    },
    getGenresTextFromArray(items) {
      return items.join(", ");
    },
    getTo(movie) {
      if (!this.disabled) {
        //move.item_protected is coming from search results module
        if (movie.protected || movie.item_protected === "true") {
          return {
            name: "protectedConfirmation",
            params: {
              movieId: movie.item_id || movie.id,
            },
          };
        } else {
          return {
            name: "movieDetail",
            params: {
              movieId: movie.item_id || movie.id,
            },
          };
        }
      }

      return {};
    },
  },
  computed: {
    loading() {
      if (this.status === "fetching") {
        return true;
      } else if (this.status === "success" || this.status === "failure") {
        return false;
      }
    },
    movieCardHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return 440;
        case "lg":
          return 430;
        case "md":
          return 360;
        case "sm":
          return 260;
        default:
          return 240;
      }
    },
  },
};
</script>
