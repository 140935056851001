<template>
  <v-img
    :min-width="width"
    :max-width="width"
    :aspect-ratio="2 / 3"
    :style="
      blur
        ? 'filter: blur(5px); -webkit-filter: blur(5px);-moz-filter: blur(5px)'
        : ''
    "
    :src="$config.managerMedia + path"
    :lazy-src="$config.managerMedia + path"
  ></v-img>
</template>

<script>
export default {
  props: {
    blur: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 80,
    },
  },
};
</script>
